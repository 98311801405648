import { DefaultTheme } from "styled-components"

const myTheme: DefaultTheme = {
  colors: {
    orange: "#f17313",
    darkOrange: "#fc2f01",
    gray: "#444",
    darkGray: "#3d3d3d",
    darkBlue: "#21375d",
    white: "white",
  },
  fontSizes: {
    verySmall: "0.8rem",
    small: "1.125rem",
    average: "1.4rem",
    medium: "2.19rem",
    huge: "2.81rem",
    big: "4.375rem",
  },
  fontWeight: {
    thin: "400",
    normal: "500",
    bold: "600",
  },
  lineHeight: {
    average: "26px",
  },
}

export { myTheme }
